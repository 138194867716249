<template>

  <head>
    <meta charset="utf-8">
    <title class="">Certificate Of Medical Examination </title>
    <!-- <link href="https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,400,600,700&display=swap" rel="stylesheet"> -->
    <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Aldrich|Fira+Sans:200,300,400,500,700,800,900|Norican&display=swap&subset=cyrillic" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,500;0,600;0,700;1,400;1,562;1,600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;600;700;800;900&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap" rel="stylesheet">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Fondamento:ital@0;1&display=swap" rel="stylesheet">

  </head>

  <body id="#">
  <page>

<!--    :style="{ 'background-image': 'url(' + bgLink + ')' }"-->
    <div class="position-relative"
         style="position: relative; padding: 0px; margin: 0px; font-family: 'Montserrat', sans-serif; ;box-sizing: border-box; background-size: cover; padding: 0.2in; background: aliceblue; width: 210mm; height:297mm; overflow: hidden;"
         :style="{ 'background-image': 'url(' + bgLink + ')', 'background-repeat': 'no-repeat', 'background-position': 'center' }"
         id="screenshot">



    <header>

        <div class="the-qr" style="position: absolute; right: 0.2in;">
          <div class="" style="display: flex; justify-content: center; align-items: center; height: 100px; width: 100%; border: 2px solid #0045a1; margin-bottom: 0.05in; padding:0.05in;"><img :src="link" style="height: 80px;"></div>
          <div class="text-right ">
            <p class="serial mt-2 " style="margin-top: 0px; padding-top: 0px; text-align: right; font-size: 10px !important;">Serial NO. {{permit.id}} </p>
          </div>
        </div>
        <div style="display: flex; justify-content: center; flex-flow: column; align-items: center; width: 100%; text-align: center; margin-top: 0.2in;">
          <img style="width: 167px; margin-bottom: 0.2in;" :src="county.logo" />
          <h1 style="font-family: 'Amiri', serif; margin-bottom: 0px; font-size:40px; font-weight: bold; margin-top: 0px ; padding-top: 0px; padding: 0px !important;">
            <bold>County Government of {{capitalizeText(county.countyName)}}</bold>
          </h1>
          <h6 style="font-family: 'Amiri', serif; font-size:24px; color: #0045a1; margin: 0px; padding: 0px; font-weight: 400; margin-bottom: 0px; padding-bottom: 0px;">{{county.tagline2}}</h6>
          <div style="background-color: #0045a1;width: 1in;height: 0.05in;"></div>
        </div>

      </header>
      <section class="row pb-5" style="padding-top: 0.5in;">
        <div class="col-12 pt-5 px-4">
          <div class="cert-heading lora-heading my-3" style="padding-top: 0px !important; margin-top: 0.2in !important;">
            <img src="./assets/ornament.svg" style="width: 170px;transform: scale(-1);" />
            <h1 class="" style="font-family: 'Fondamento', cursive !important; font-size: 60px; margin: 0px; padding: 0px; "><strong style=" font-family: 'Fondamento', cursive !important; ">Health Certificate</strong></h1>
            <h6 class=" " style=" margin-top: 0px; padding-top: 0px; font-size: 18px; font-weight: 400; font-family: 'Amiri', serif; margin-bottom: 0.2in; padding-bottom: 0px;">(Food Hygiene) Regulations</h6>


          </div>
          <!-- main content-->
          <div style="display: flex; justify-content: center; align-items: center; flex-flow: column;">
            <p class=" col-12 p-0 spacing-1 line-height-2 mt-4 e " style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; " id="demo ">This Licence is hereby granted to <span class="content-field
                            ">{{permit.businessName}}</span> to sell, prepare, pack, store or display food at plot No. <span class="content-field ">{{permit.plotNumber}} </span>  <strong> </strong>situated at  <span class="content-field
                            "> {{permit.physicalAddress}} </span> Nature of Business <span class="content-field
                            ">{{permit.businessDes}} </span></p>
            <p class=" mb-3 lora-spac " style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; ">This license is not transferable to any other person without the permission of the Health Authority endorsed hereon.</p>

            <p style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; ">This licence is not transferable to any other premises.</p>

            <img src="./assets/ornament.svg" style="width: 170px; margin-bottom: 0.2in;" />
          </div>
          <!-- examination details-->
          <div class="row mt-0 spacing-1 ">

            <div class="col-7 dropleft text-left ">
              <!-- examination details-->
              <div class="col-12 dropleft text-left p-0 mb-3 mt-5" style="font-size: 12px !important;">
                <p class="text-capitalize mb-0 d-flex align-items-end pb-2" style="font-size: 12px !important; display: flex; align-items: center; margin-bottom: 0px; padding: 0px;">
                  <span>Sign: </span>
                  <span><img :src="county.signatureHealth" style="height: 45px;" class=""></span>
                </p>
                <div class="text-capitalize mb-0">
                  <strong style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">{{permit.issuingOfficer}} </strong><br>
                  <strong style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">Deputy director health</strong>
                  <span class="text-capitalize" style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">  Date: {{permit.dateOfIssue}}</span>
                </div>
              </div>


            </div>
          </div>




        </div>


      </section>
      <!-- fotter area-->
      <div class=" " id="# ">
        <footer class="row font-14 ">

          <div style="position: absolute;bottom: 0.2in;right: 0.2in;left: 0.2in;display: flex;flex-flow: column;">
            <div>
              <div style="display: flex; justify-content:space-between; align-items: center; width: 100%; ">
                <div class="">
                  <p class="dropleft" style="font-size: 10px !important;">NB: This Certificate is valid for {{permit.duration}} Months Only
                  </p>
                </div>
                <div class=" ">
                  <p class="dropleft font-9 " style="font-size: 10px !important;"><b>Expiry Date {{permit.endDate}}</b></p>
                </div>
              </div>
            </div>

            <div class="hr1 col-12 px-4 " style="background: #0045a1;"></div>

            <div class="col-12 ">
              <p class="mb-3 " style="font-weight: 300; font-size: 10px !important;">Granting of this certificate does not exempt the above named person from adhering and complying to the current established public health guidelines and restrictions by the Government of Kenya.</p>
            </div>
          </div>

          <div class="col-6 d-none " style="display: none; ">
            <p class="dropleft font-14 "><b>City Hall</b>, | Po Box 30075-00100 Nairobi, Kenya
            </p>
          </div>



        </footer>
      </div>

      <div class="row footer margin spacing-1 mt-5 ">

      </div>



      <div class=" " style="display:flex; flex-flow: column; position: absolute; bottom: 1.5in; height: 50px; display: none;">
        <p style="font-size: 12px !important;">Powered By</p>
        <img src="assets/nbk_logo.svg " class=" " style="height: 50px; ">
      </div>
    </div>
  </page>

  <button class="print-btn " onclick="window.print() ">Print Certificate</button>
  <!-- <a href="javascript:new_screenshot() ">Download pdf</a>-->

  <div class="reportContents "></div>
  <!-- scripts -->

  <!-- scripts -->

  </body>


</template>

<script>
/* eslint-disable */

import {execute, base, fire, health} from "@/api";
import numWords from 'num-words';
import moment from "moment";
export default {
  name: "FirePermit",
  data() {
    return {
      bgLink: base+'/logo/bg-image-health.jpg',
      permit: {
        id: null,
        billNo: null,
        businessID: null,
        businessName: null ,
        duration: null,
        billTotal: null,
        receiptAmount: null,
        billBalance: null,
        startDate: null,
        endDate: null,
        statusID: null,
        status: null,
        printable: null,
        issuingOfficer: null,
        designation: null,
        dateOfIssue: null,
        stageStatus: null,
        comments: null,
        incomeTypeId: null,
        feeID: null,
        subCountyID: null,
        subCountyName: null,
        wardID: null,
        wardName: null,
        plotNumber: null,
        physicalAddress: null,
        buildingName: null,
        buildingOccupancy: null,
        floorNo: null,
        roomNo: null,
        premiseSize: null,
        numberOfEmployees: null,
        tonnage: null,
        businessDes: null,
        businessCategory: null,
        businessSubCategory: null,
        incomeTypeID: null,
        businessEmail:null,
        postalAddress: null,
        postalCode: null,
        businessPhone: null,
        contactPersonNames: null,
        contactPersonIDNo: null,
        businessRole: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        fullNames: null,
        ownerID: null,
        ownerPhone: null,
        ownerEmail: null,
        kraPin: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        lat: null,
        lng: null,
        lastRenewed: null,
        permitsCount: null,
        brimsCode: null,
        description:null,
      },
      link:'',
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        sealFire: null,
        signatureFire: null,
        signatureHealth: null,
        QRLink:null
      },
      trimmedSentences: []
    };
  },
  computed: {
    year() {
      let date = new Date(this.permit.startDate);
      let year = date.getFullYear();
      return year;
    }},
  mounted() {

    // Get the hash from the URL
    const hash = window.location.hash;

    const numberFromURL = hash.split('/')[3];


    // If number is passed in the URL
    if (numberFromURL) {
      console.log('Number from URL:', numberFromURL);
      this.printPermit(numberFromURL);
    }
    else {
      // Number is not passed in the URL, check localStorage
      let params = {};
      try {
        params = JSON.parse(localStorage.getItem('params'));
        console.log('Number from localStorage:', params.businessID);
        this.printPermit(params.businessID);
      } catch (error) {
        console.error('Error parsing localStorage:', error);
      }
    }

    //6832
    //this.printFirePermit("6832");


  },
  methods: {
    currentDate(){
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let suffix = hour >= 12 ? "PM" : "AM";
      hour = hour % 12 || 12;
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + (month + 1) : month + 1;
      minute = minute < 10 ? "0" + minute : minute;
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let monthName = months[month - 1];
      let formattedDate = `${day} ${monthName} ${year} ${hour}:${minute} ${suffix}`;
      return formattedDate;
    },
    formattedDate(date2) {
      let date = new Date(date2);
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let monthName = months[month];
      return `${day} ${monthName} ${year}`;
    },

    numberWords(num){
      return numWords(num)
    },

    showDate() {
      const dateObj = new Date();
      const currentDate =
          dateObj.getDate() +
          "/" +
          dateObj.getMonth() +
          "/" +
          dateObj.getFullYear();
      return currentDate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    printPermit(businessID) {
      const data = new FormData();
      data.append("function", "printPermit");
      data.append("businessID", businessID);

      execute(data,health)
          .then((res) => {
            if (res.data.success) {
              this.permit = res.data.data.permit;
              this.county = res.data.data.county


              this.trimmedSentences = this.permit.description
                  .trim()
                  .slice(1, -1) // Remove square brackets
                  .split(",") // Split by commas
                  .map(sentence => sentence.trim())
                  .filter(sentence => sentence !== "" && sentence !== null && !sentence.includes("Recommendations"));


              this.link = this.county.QRLink+"Business ID: "+this.permit.businessID+" |\n "
                  +this.permit.businessName+" | start date: "+ this.permit.startDate+" | Expiry:"+this.permit.endDate;
            } else {
              alert(res.data.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    formattedTime(time) {
      return moment(time).format('hh:mm A')
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    },

    mailTradePermit(){
      const data = new FormData();
      data.append("function", "mailTradePermit");
      data.append("businessID", this.$route.params['data']);

      execute(data,fire)
          .then((res) => {
            alert(res.data.message);
          })
          .catch((e) => {
            alert(e.message);
          });

    }

  },
}
</script>


<style scoped>
@charset "utf-8";
/* CSS Document */
/*fonts*/

/*@font-face {
  font-family: "Libre Baskerville";
  src: url("");
  src: url("fonts/librebaskerville-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Norican";
  src: url("");
  src: url("fonts/norican-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Aldrich-down";
  src: url("");
  src: url("fonts/aldrich-regular-webfont.woff") format("woff");
}*/
/*fonts*/

body {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  background: repeating-linear-gradient(45deg, #0145c2, #0145c2 10px, #2196F3 10px, #2196F3 20px);
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.qr-code {
  height: 70px;
}

* {
  box-sizing: border-box;
}

.libre-bold {
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
}

.libre-reg {
  font-family: 'Libre Baskerville', serif;
  font-weight: 300;
}

.a4-size {
  width: 595px;
  min-height: 990px;
 /* background: url("../img/nairobi2.jpg");*/
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 15px;
  padding-bottom: 15px;
}

.qr-container {
  width: 105px;
  height: 105px;
  border: 1px solid black;
}

p {
  margin-bottom: 7.5px;
}

h4 {
  font-size: 15px;
  font-weight: bold;
}

.kenya {
  display: block;
  height: 9px;
  color: #0145c3;
  width: 100%;
  background: #be0000;
  border-bottom: solid 2px white;
  /* margin-bottom: 5px; */
  border-top: 2px solid white;
}

.kenya2 {
  width: 100%;
  background: #006818;
  height: 4px;
}

.kenya3 {
  width: 100%;
  background: black;
  height: 4px;
}

header h3 {
  font-size: 27px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}

header h2 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Libre Baskerville', serif;
  text-transform: capitalize;
  white-space: nowrap;
}

.knh-title h2,
.knh-title h5 {
  font-family: 'Raleway', sans-serif;
}

.knh-title h2 {
  font-weight: 800;
  font-size: 33px;
  color: #0145c3;
}

.knh-title h5 {
  ont-size: 23px;
  letter-spacing: 7px;
  color: #00abe4 !important;
}

.blue {
  display: block;
  height: 8px;
  color: #0145c3;
  width: 100%;
  background: #0145c3;
  border-bottom: solid 2px #5992bf;
  margin-bottom: 5px;
}

header h5,
h5 {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Libre Baskerville', serif;
  text-transform: capitalize;
  white-space: nowrap;
}

.cert-heading {
  text-align: center;
}

.duration {
  height: 7.5px;
  padding: 0 7.5px;
  border: 1px solid black;
}

.table td {
  padding: 7.5px;
}

.green {
  display: block;
  height: 8px;
  color: green;
  width: 100%;
  background: #00421B;
  border-bottom: 2px solid #FFDE00;
  margin-bottom: 5px;
}

.note {
  font-size: 8px;
}

.nbk {
  height: 50px;
  /* float: right; */
  /* height: 30px; */
  /* position: absolute; */
  right: 10px;
  bottom: -35px;
}

.locence-no {
  background: rgba(255, 222, 0, .35);
}

h1 {
  font-family: 'Norican', serif;
  font-size: 24px;
}

p {
  font-family: 'Fira Sans', sans-serif;
}

h2 {
  font-size: 34px;
  font-weight: bold;
  font-family: ro;
}

.serial {
  font-family: 'Aldrich-down', sans-serif;
}

.hr1 {
  background: black;
  width: 100%;
  height: 1px;
  margin-bottom: 2px;
}

.footer {
  font-size: 9px;
  font-weight: 700;
}

.hr2 {
  background: black;
  width: 100%;
  height: 4px;
}

.other-num {
  left: 15px;
  right: 15px;
  font-size: 12px;
  font-weight: 500;
  top: 8px;
}

.content-field {
  font-family: 'Share Tech Mono', monospace;
  font-weight: bold;
  text-decoration: underline;
}

footer p {
  font-size: 7px;
}

.footer-container {
  bottom: 0px;
  width: 95%;
}

.font-10 {
  font-size: 10px;
}

.font-9 {
  font-size: 9px;
}

.figure-img img {
  width: 100%;
  max-width: 250px;
}

@media print {
  .print-btn {
    display: none !important;
  }
  body {
    max-height: 100vh;
    overflow: hidden;
    /*
height: 297mm !important;
width: 210mm !important
*/
  }
  .lora-heading h1 {
    font-weight: 800 !important;
    font-weight: bold !important;
  }
  .margin {
    margin-top: 40px !important;
  }
  .nbk {
    height: 100px !important;
  }
  .signature {
    height: 78px !important;
  }
  .qr-code {
    height: 140px !important;
  }
  .figure-img img {
    width: 100%;
  }
  .cert-title h2 {
    font-size: 50px;
  }
  .cert-title h5 {
    font-size: 27px;
  }
  .cert-heading h1 {
    font-size: 60px;
  }
  .cert-heading h5 {
    font-size: 40px;
  }
  .knh-title h2 {
    font-weight: 700;
    font-size: 70px !important;
    color: #0145c3;
  }
  .knh-title h5 {
    font-size: 50px;
  }
  .nms-title h2 {
    font-weight: 700;
    font-size: 45px !important;
  }
  .nms-title h5 {
    font-size: 25px !important;
  }
  p {
    font-size: 20px !important;
  }
  .a4-size {
    margin: 5px 7px;
    height: calc(100vh - 19px);
    width: 100%;
    /*		height: 200vh !important;*/
  }
  .stamp img {
    height: 300px;
  }
  .font-9,
  .font-10 {
    font-size: 14px;
  }
  .font-14 {
    font-size: 14px !important;
  }
  .lora-heading h1 {
    font-weight: bold !important;
  }
  .lora-heading h1 {
    font-weight: bold !important;
    font-size: 60px;
  }
  h6 {
    font-size: 20px;
  }
}

.lora-space {
  margin-bottom: 50px !important;
}

.signature {
  height: 50px;
}

.spacing-1 {
  letter-spacing: 1px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px;
}

.line-height-2 {
  line-height: 2;
}

.print-btn {
  padding: 7px 15px;
  background: #FF9800;
  border: none;
  position: fixed;
  bottom: 30px;
  left: 30px;
  color: white;
  font-size: 18px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.lora-heading {
  margin-top: 150px !important;
}

.lora-heading h1,
.lora-heading h5 {
  font-family: 'Lora', serif !important;
  text-transform: capitalize;
  font-family: Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif" !important;
}

.lora-heading h1 {
  font-weight: 600;
}

.lora-heading {
  padding-top: 30px !important;
}

.stamp img {
  height: 76px;
  transform: rotate(-27deg);
}

* {
  box-sizing: border-box;
  /* font-family: 'Montserrat', sans-serif; */
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

@media print {
  .print-btn {
    display: none;
  }
  body {
    margin: 0px !important;
    padding: 0px !important;
  }
  @page {
    /* size: auto; */
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    /* width: 210mm;*/
    /* height: 297mm; */
    size: A4 portrait;
    margin: 0px;
    margin-bottom: 0px;
    margin: 0px;
  }
}

@page {
  /* size: auto; */
  /* auto is the initial value */
  /* this affects the margin in the printer settings */
  /* width: 210mm;
      height: 297mm; */
  size: A4 portrait;
  margin: 0px;
  margin-bottom: 0px;
  margin: 0px;
}
</style>




